import { defineStore } from 'pinia'
import httpClient from '../http-client.js';
import { dashboardApiEndpointConsts } from '../constants.js'
  
export const useUserStore = defineStore('user', {
    state: () => ({
        activeAgencyNumber: null,
        agencyList: [],
        defaultAgencyNumber: null,
        removedUserXYZAccountsCount: 0,
        searchedUsersAgencyAccountText: null,
        userAccountsCount: null,
        userRole: null,
        SearchedUsersAgentNo: null,
        searchedUsersAgentNameOrEmailText: null
    }),
    persist: true,
    getters: {
        //Returns whether the active agency is dedicated.
        isDedicatedActiveAgency() {
            let activeAgency = this.agencyList?.find((item) => item.agencyNumber == this.activeAgencyNumber)

            if(activeAgency != null) {
                return activeAgency.priority === "D";
            } else {
                return false;
            }
        },
        activeAgencyParent() {
            let activeAgency = this.agencyList?.find((item) => item.agencyNumber == this.activeAgencyNumber)

            if(activeAgency != null) {
                return activeAgency.parentAccountNumber;
            } else {
                return 0;
            }
        }
    },
    actions: {
        addAgencyToUserAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.ADD_AGENCY_TO_USER,
                payload: {
                    "AgencyNumber": args.agency.AgencyNumber,
                    "UserId": args.agency.UserId,
                    "CarrierList": args.agency.CarrierList,
                    "IsDefaultAgency":args.agency.IsDefaultAgency,
                }
            });
        },
        addUserResetPwdLinkAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.ADD_USER_RESET_PWD_LINK,
                payload: args.resetPasswordDetails,
            });
        },
        approveAdminRequestsAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.UPDATE_APPROVED_REQUESTS,
                payload: { "UserId": args.userId },
            }).then(function (response) {
                return response.data;
            });
        },
        changeUserPasswordAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.CHANGE_USER_PASSWORD,
                payload: args.changePasswordModel,
            }).then(function (response) {
                return response;
            });
        },
        checkUserStatusAsync(args) {
            return httpClient.callHttpClient({
                method: 'GET',
                endpoint: dashboardApiEndpointConsts.GET_USER_STATUS + args.userName,
                payload: {},
            }).then(function (response) {
                return response.data;
            });
        },
        clearAgencyNotificationFlagAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.CLEAR_NOTIFICATION_FLAG,
                payload:{ "UserId" : args.userId },
            }).then(function (response) {
                this.agencyList.forEach(a => a.showAgencyChangeNotification = false);                
            });
        },
        createActiveUserAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.GET_CREATE_ACTIVE_USER,
                payload: args.agentInformation,
            }).then(function (response) {
                return response;
            });
        },
        createInactiveUserAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.GET_CREATE_USER,
                payload: args.createUserModel,
            }).then(function (response) {
                return response;
            });
        },
        createUserPasswordAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.CREATE_USER_PASSWORD,
                payload: args.createNewPasswordDetails,
            }).then(function (response) {
                return response;
            });
        },
        deactivateUserAsync(args) {
            return httpClient.callHttpClient({
                method: 'GET',
                endpoint: dashboardApiEndpointConsts.DEACTIVATE_USER + "/" + args.userName,
                payload: {},
            }).then(function (response) {
                return response.data;
            });
        },
        denyAdminRequestsAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.UPDATE_DENIED_REQUESTS,
                payload: { "UserId": args.userId },
            }).then(function (response) {
                return response.data;
            });
        },
        execRemoveUserFromAgencyAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.REMOVE_USER_FROM_AGENCY,
                payload: { "userId": args.userId, "AGNT_NUM":  args.agentNumber},
            }).then(function (response) {
                return response.data;
            });
        },
        execSendPasswordRecoveryLinkAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.POST_FORGOTPASSWORD,
                payload: {
                    username: args.userName,
                    factorType: args.factorType
                },
            }).then(function (response) {
                return response;
            });
        },
        execSendRequestAdminAccessEmailAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.SEND_REQUEST_ADMIN_ACCESS_EMAIL,
                payload: args.user,
                ignoreErrors: true
            }).then(function (response) {
                return response.data;
            });
        },
        getAdminRequestsAsync(args) {
            return httpClient.callHttpClient({
                method: 'GET',
                endpoint: dashboardApiEndpointConsts.GET_ADMIN_REQUESTS + "?v="+ Date.now(),
                payload: {},
            }).then(function (response) {
                return response.data;
            });
        },
        getIsRegisteredUserAsync(args) {
            return httpClient.callHttpClient({
                method: 'GET',
                endpoint: dashboardApiEndpointConsts.GET_IS_REGISTERED_USER + "/" + args.courseID + "/" + args.oktaID,
                payload: {},
            }).then(function (response) {
                return response.data;
            });
        },
        getListOktaUsersAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.GET_OKTA_AGENT_LIST,
                payload: {
                    AgentNumber: args.agentNumber,
                    SearchTerm: args.searchTerm,
                    SortBy: args.sortBy,
                    SortOrder: args.sortOrder,
                    ShouldFilterByRole:args.shouldFilterByRole,
                    RoleToFilterBy: args.roleToFilterBy
                },
            }).then(function (response) {
                return response.data;
            });
        },
        getOktaUsersByAgentNameOrEmailAsync(args)
        {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.GET_OKTA_AGENTS_LIST_By_NAMEOrEMAIL,
                payload: {
                    SearchTerm: args.searchTerm,
                    SortBy: args.sortBy,
                    SortOrder: args.sortOrder,
                    ShouldFilterByRole: args.shouldFilterByRole,
                    RoleToFilterBy: args.roleToFilterBy
                },
            }).then(function (response) {
                return response.data;
            });
        },
        getSignInDetailsAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.GET_SIGN_IN_DETAILS,
                payload: {
                    userId: args.userId,
                    AgencyNumber : args.agencyNumber
                },
            }).then(function (response) {
                return response;
            });
        },
        getUserDetailsAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.GET_USER_DETAILS,
                payload: { 
                    userId: args.userId,
                    isCobranded: args.isCobranded,
                    AgencyNumber: args.agencyNumber
                },
            }).then(function (response) {
                return response;
            });
        },
        getUserProfileAsync(args) {
            return httpClient.callHttpClient({
                method: 'GET',
                endpoint: dashboardApiEndpointConsts.GET_USER_PROFILE + args.userId,
                payload: { 
                    userId: args.userId
                },
            }).then(function (response) {
                return response;
            });
        },
        getUserResetPwdLinkAsync(args) {
            return httpClient.callHttpClient({
                method: 'GET',
                endpoint: dashboardApiEndpointConsts.GET_USER_RESET_PWD_LINK + "?" + "guid=" + args.guid,
                payload: {},
            }).then(function (response) {
                return response;
            });
        },
        setUserAgencies(agencies) {
            var defaultAgency = null;

            if (agencies.length > 0) {
                var defaultAgencies = agencies.filter(agent => agent.isDefaultAgency);
                if (defaultAgencies && defaultAgencies.length > 0) {
                    defaultAgency = defaultAgencies[0].agencyNumber;
                    this.activeAgencyNumber = defaultAgency;
                    this.defaultAgencyNumber = defaultAgency;
                }

                this.agencyList = [];
                agencies.forEach(agency => {
                    this.agencyList.push(agency)
                });
            }

            return defaultAgency;
        },
        updateAgentContactPrefsAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.UPDATE_AGENT_CONTACT_PREFS,
                payload: args.userData,
            }).then(function (response) {
                return response;
            });
        },
        updateAgentInformationAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.UPDATE_AGENT_INFORMATION,
                payload: args.agentInformation,
            }).then(function (response) {
                return response;
            });
        },
        updateUserAgentCarriersAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.UPDATE_USER_AGENT_CARRIERS,
                payload: args.agentInformation,
            }).then(function (response) {
                return response;
            });
        },
        updateUserDefaultAgencyAsync(args) {
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.UPDATE_USER_DEFAULT_AGENCY,
                payload: {
                    "UserId": args.userId,
                    "AGNT_NUM":  args.agencyNumber,
                    "ShowAgencyChangeNotification": args.showAgencyChangeNotification
                },
                bearerToken: null //Passing bearer token as user's token has not made it to local storage yet.
            }).then(function (response) {
                return response.data;
            });
        },
        execResetMfaAsync(args){
            return httpClient.callHttpClient({
              method: 'POST',
              endpoint: dashboardApiEndpointConsts.RESETMFA,
              payload: { "UserId": args.userId }
            })
            .then(function (response) {
              return response;
            });
          },
          notifyUserLoggedInAsync(args) {
            return httpClient.callHttpClient({
                method: 'GET',
                endpoint: dashboardApiEndpointConsts.NOTIFY_USER_LOGGED_IN + args.userName,
                payload: {},
            }).then(function (response) {
                return response;
            });
        },

        execVerifyAgentAgencyInformationAsync(args){
            return httpClient.callHttpClient({
                method: 'POST',
                endpoint: dashboardApiEndpointConsts.VERIFY_AGENT_AGENCY_LINK,
                payload: { "userId": args.userId, "AGNT_NUM":  args.agentNumber},
            }).then(function (response) {
                return response.data;
            });
        }
                                                                      
    },  //end of actions
})
